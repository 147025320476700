@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');

:root{

    --primary-color:#912BBC;
}


*{

    margin:0;
    padding:0;
    
}

body{
    font-family: "Cabin", sans-serif;

}




a{
    text-decoration: none;
}


.not_found{


    color:grey;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    font-size: 30px;

}

.slidein{
   
    bottom: 0;
    left: 0;
    right: 0;
    margin-top:20vh;
    width: 100%;
    height: 80vh;
    transform-origin: bottom;
    z-index: 1000;
    background-color: aliceblue;
    
    
}
