.main-pages{

    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
   padding: 5px;
   box-sizing: border-box;
   margin-bottom: 20px;
    
}

.page{

    user-select: none;
    text-align: center;
    /* margin: 15px;
    border: 1px solid grey;
    border-radius: 3px;
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: silver;
    box-sizing: border-box; */
}
.page.active{

    background-color: black;
    color:white;
}
.page.disable{

   visibility: hidden;
}

@media only screen and (max-width:700px){

    .main-pages{

      
        
    }
    .page{

        width:7%;
        margin: 2%;
    }
}