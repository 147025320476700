@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.search-bar {

    width: 70%;
    height: 50px;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 5px;
    box-sizing: border-box;
    padding: 0px 40px 0px 15px;
    font-size: 18px;
    color: grey;
    /* position: relative; */
    display: flex;
    align-items: center;

}

.header-mid-part{

    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.noresult {

    display: none;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}
.search-ul2{
/* width: 100%; */
    
}

.search-ul-li {
    position: absolute;
    z-index: 5;
    width: 70%;
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    background-color: white;
    margin-top: 50px;

    display: none;
    




}


body{

    color:black;
}
.header-ul-li{
    
    display: flex;
    
    
    
}
.header-lis{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 10px;
   padding: 10px;
    height: 35px;
    border-radius: 5px;
    font-weight: 500;
}
.header-lis:hover{

    color: white;
    background-color: #7c73e6;
}
.header-ul-li-mid{


    display: flex;
    justify-content: center;
    width: 60%;
}
.header-lis.active{
   

    color: white;
    background-color: #7c73e6;
}
.header {

    width: 100%;
    height: 90px;
    /* background-color: #912BBC; */
    background-color: white;
    /* color:white; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    /* box-shadow: 0px 0px 7px 1px rgb(114, 103, 98); */
    box-shadow: 0px 4px 7px -4px rgb(114, 103, 98);
    position:sticky;
    top:0;
    z-index: 1000;
}

.header-wrap {
    display: flex;
    align-items: center;
    flex-basis:250px;
   
    
}
 


.login_div{

    /* margin:0px 20px 0px 20px; */
    /* color: white; */
    /* cursor: pointer; */
    border-radius: 50%;
    border: 2px solid black;
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    
      display: flex;
    justify-content: center;
    align-items: center;
}
.icons_div{

   display: flex;
   justify-content: flex-end;
   align-items: center;
   right: 0px;
   flex:1;


}
.main_login2{
display:none;

}
.login_div2{

    color:black;
    justify-content: center;
    align-items: center;
    height: 60px;
    
    font-size: 25px;
    width:10%;
    margin-right: 1%;
    
    border-radius: 50%;
    border: 2px solid black;
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    display:none;
}
.login_div:hover{
    /* color: #F0EBE3; */
    transform: scale(1.1);
}
.search-li:hover{

    background-color:#F0EBE3;
}

/* .fa-solid.fa-user:hover{
    color:#F0EBE3;
} */
.fa-solid.fa-cart-shopping:hover{

    /* color:#F0EBE3; */
    /* transform: scale(1.1); */
}

.heading-main {
    color: black;
   
    cursor: pointer;
    box-sizing: border-box;
    font-size: 35px;
    font-weight:950;
    
   
    

}

.cart-div{
    /* transition: 0.3s ease-in-out; */
    border-radius: 50%;
    border: 2px solid black;
    height: 27px;
    width: 27px;
    
    
    

}
.magnify-search{
    /* border-radius: 50%;
    border: 2px solid black;
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px; */
    /* transition: 0.3s ease-in-out; */
    
}
.magnify-search:hover{

    transform: scale(1.1);
}
.cart-div:hover{
transform: scale(1.1);
}
.heading-main:hover{

    color: #7c73e6;
}
.fa-cart-shopping{

    font-size: 17px;
    
    margin-left: 2%;
}
.my_orders{

    /* color:white; */
    margin:0px 20px 0px 20px;
}
.cart-number{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-top: -10px;
    background-color:#7c73e6;
    color: white;
    font-size: 15px;
    /* transition: 0.3s ease-in-out; */
  

}
.cart-number:hover{

    /* transform: scale(1.1); */
}
.cart-div{
    /* margin:0px 0px 0px 0px; */
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-right: 120px;
    
}


.header2 {
    width: 100%;
    height: 80px;
    background-color:magenta;
    justify-content: center;

    border-top: 1px solid grey;
align-items: center;
    display: none;
}

.search-bar2 {
    width: 70%;
    height: 50px;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 5px;
    box-sizing: border-box;
    padding-left: 20px;
    font-size: 20px;
    color: grey;
    display: none;
    position: relative;

}


.fa-solid.fa-magnifying-glass.searchone{
    position: absolute;
    font-size: 20px;
    top:15px;
    right: 17%;
    
}
.search-bar-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.search-ul{
    width: 100%;
}

/* .fa-regular.fa-user{

    display: none;
    color: white;
    font-size: 30px;
    width:10%;
    
    justify-content: center;
} */
.search-li{
    width: 100%;
    display: inline-flex;
}
.search-bar2-main-div{
    width: 70%;
    position: relative;
    display: flex ;
    justify-content: center;
}
.search-bar2{
    width: 100%;
}
.yes{
    display: none;
}
.profilehov{
    position: absolute;
    z-index:2 ;
    transform: translateX(-43%);
    margin-top: 0px;
    
}
.loginhide{
    display: none;
}
.profile-main-div{

    position: relative;
    /* height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center; */
    
}
.profilepic{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 27px;
    width: 27px;
    background-color: green;
    /* color:white; */
    margin:0px 20px 0px 20px;
}
.profilepic:hover{

    
    /* cursor: pointer; */
    
}
.my_orders:hover{
    
    color: #F0EBE3;
}

.searchIcon2{
    position: absolute;
    font-size: 18px;
    right: 5%;
    top:50%;
    transform: translateY(-50%);
}
.search-ui{

    max-height: 40px;
    max-width: 40px;
    min-width: 40px;
    min-height: 40px;
}

.header-bar{

    display: none;
}

.navbar-ul{

    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-navbar{

    height: 100vh;
    width: 85%;
    background-color: white;
    position: fixed;
    
    top:48px;
    color:#323232;
    background-color:#DDD0C8;
    transform: translateX(-101%);
    transition: 0.3s ease-in-out;
    z-index: 900;
    

    
}
.navopen{

    
    transform: translate(0%);
    
}

.navbar-li{

    font-size: 20px;
    margin: 10px;
  
    width: 100%;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin-left: 70px;
    font-weight: 500;

}
.main_login1{
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;

}
.cart-div:hover,.magnify-search:hover,.login_div:hover{

    /* transform: scale(1.1); */
    cursor: pointer;
}
@media only screen and (max-width:330px){


    .search-bar2{

        font-size: 18px;
        padding-left: 10px;
    }
}

@media only screen and (max-width:300px){
    .my_orders,.profilepic,.cart-div,.login_div{
        margin:3px;

    }
    .my_orders{
        font-size: 12px;
    }
    .fa-solid.fa-cart-shopping{
        /* transition: 0.3s ease-in-out; */
        /* font-size: 10px; */
    }
    .profilepic{
        height: 30px;
        width: 30px;
    }
    .cart-number{

        height: 15px;
        width: 15px;
    }

    .search-bar2{

        font-size: 16px;
        padding-left: 5px;
    }
   

}
@media only screen and (max-width:700px) {
    .search-bar {

        display: none;

    }
    .header-bar{

        display: block;
    }
    .header-ul-li-mid{

        display: none;
    }
    
    .my_orders,.profilepic,.cart-div,.login_div{
        margin:5px;

    }
    
    .header-mid-part{

        display: none;
    }
    .profilehov{
        
        transform: translateX(-70%);
        
    }

    .extra-div {
        display: none;
    }

    .search-bar2 {

        display: block;
        width: 100%;
        position: relative;

    }

    .header2 {
        display: flex;
        flex-direction: column;
        height: 70px;
    }

    .heading-main {

        font-size: 15px;
    }

    .search-ul-li2 {

        position: absolute;
width: 100%;
        height: 300px;
        overflow: scroll;
        background-color: white;
z-index: 5;
        display: none;
        box-sizing: border-box;
        margin-top: 50px;

    }
    .header{

        height: 75px;
    }
.header-wrap{

    
    position: absolute;

}

.magnify-search,.cart-div,.login_div{

    margin: -5px;
}


}

@media only screen and (max-width:1000px) {

    .cart-div{

        margin-right: 0px;
    }


    .search-bar{
        display: none;
    }
    .header2{
        display: flex;
    }
    .search-bar2{
        display: block;
    }
    .login{
        width: 20%;
    }
    .login_div2{
        display: flex;
    } 
    .main_login2{

        display: flex;
    }
    .main_login1{
        
        
    }
    
    .login{
        display: none;
    }
    
    .fa-solid.fa-magnifying-glass{
        
    }
    .fa-solid.fa-magnifying-glass.searchIcon2{
        display: flex;
    }
    
}


body{

    color:black;
}