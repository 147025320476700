.login-input {

    width: 300px;
    height: 50px;
    margin: 10px 0px;
    outline: none;
    border-radius: 5px;
    border: 1px solid grey;
    padding-left: 10px;
    box-sizing: border-box;

}

.login-main-div {

    width: 100%;
    height: 100%;
  
    
    


}

.login-div {
    /* top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    margin:  0 auto;
    width: 45%;
    height: 65%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    background-color: white;



}

.login-wrap {

    display: flex;
    justify-content: flex-end;

}

.login-wrap2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cut-login {
    margin-right: 1%;

}

.submit-button {
    background-color: var(--primary-color);
    color: white;
    outline: none;
    font-size: 25px;

    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    width: 300px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    
}

.checkbox-div {

    display: flex;
    justify-content: center;

}


.submit-button-div {
    display: flex;
    justify-content: center;
}

.create-account {
    background-color: rgb(66, 183, 42);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    border-radius: 5px;

}

.line-div {

    display: flex;
    justify-content: center;
    margin: 15px;
}

.line {
    border-bottom: 1px solid grey;
    width: 300px;
}

.create-account-div {
    display: flex;
    justify-content: center;

}

.create-account-wrapper {
    margin-top: 0px;
}
.google-login {
    /* Define your styles here */
    width: 100%;
    height: 50px;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
    /* Add any other styles as needed */
}




@media only screen and (max-width:700px) {

    .login-div {
        width: 100%;
        height: 100%;
    }

}