.ul-addtocart{
/* display: flex;
border:3px solid grey;
padding: 5px;
list-style: none;
flex-direction: column; */
}
.cart_empty{
color:grey;
font-size: 30px;
    
}

.li-addtocart{

    /* margin: 10px;
    border: 2px solid grey;
    display: flex; */

}


.cart-main-div{

    /* display: flex; */

}
.li-image{

    max-width: 100px;
    max-height: 100px;
}

.main-div-addtocart{
    /* display: flex;
    justify-content: space-around; */
    padding-bottom: 15px;

}
.screen-height{

    min-height:calc(100vh - 70px) ;
    
}

@media only screen and (max-width:700px){
    .main-div-addtocart{
        /* display: flex;
        flex-direction: column;
        align-items: center; */
        
        
    }

}