
.product-load{

    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.thumbnail {
    width: 100px; /* Full width of the container */
    height: 100px; /* Fixed height for thumbnails */
    object-fit: cover; /* Ensures the image covers the dimensions */
    cursor: pointer; /* Indicates clickable image */
    /* border: 1px solid #ddd; Optional: Adds a border around the image */
    border-radius: 5px; /* Optional: Rounds the corners of the image */
  }

  .main-image-prod {
    width: calc((4 * 100px) + (8px * 3) ) ;/* Adjust to fit your layout */
    height: calc((4 * 100px) + (8px * 3) ) ; /* Keeps the aspect ratio of the image */
     object-fit: cover;/* Ensures the image covers the dimensions */
  }
  .image-container-prod {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Adjust as needed */
    width: 100px; /* Fixed width for thumbnails */
  }
.cards1{

    width: 100%;
    display: flex;
    margin-top: 10%;
    flex-direction: column;
    margin-left: 10%;
   
}
.sliders-container{

    width: 500px;
    height: 500px;
   margin-top: 10%;
    
    

}

.slide-img{

    width: 100%;
    
}
.imgs2{

    max-width: 400px;
    max-height: 400px;
    margin-left: 50%;
    transform: translate(-50%);
}
.main-product-detail{
width:100%;
    display: flex;
    justify-content: space-between;
}
.counters{

    display: flex;
    justify-content: space-evenly;
    width: 10%;
    align-items: center;
    height: 50px;
}
.shop{

    width:200px;
    height:40px;
    border-radius: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
    border: 1p solid grey;
    margin: 5px;
}
.addtocart{

    
}
.addtocart.addtocart-disable{
    display: none;
}
.buynow{

    background-color: rgb(66, 183, 42);
}
.counter-disable{
    display: none;
}


.prod-border{

    border:3px solid black;
}

@media only screen and (max-width:640px){
    .thumbnail{

        height: 65px;
        width: 65px;
    }
    .image-container-prod {
       
        flex-direction: row;
        width: 80px;
        height: 80px;
        
      }
      .main-product-detail{
        flex-direction: column;
        align-items: center;
    }

}
@media only screen and (max-width:700px){


    .main-image-prod{
        height: 220px;
        width: 250px;
        width: calc((4 * 60px) + (9px * 3) ) ;/* Adjust to fit your layout */
        height: calc((4 * 50px) + (8px * 4) ) ;
        
    }

    

    
    .sliders-container{

        width: 80%;
        height: 50%;
        justify-content: center;
    }
    .cards1{
        width: 90%;
        margin-top: 20px;
        display: flex;
        margin-left: 0px;
        align-items: center;
    }
    .imgs2{

        width: 300px;
    }
}