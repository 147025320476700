.nav-main-div{
    width: 100%;
    height: 55px;
    background-color: white;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}
.ul-div{
height: 100%;
width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
   
    list-style: none;
}

.fa-solid.fa-user{

    font-size: 25px;
}

.nav.active{
    
    
    color:#912BBC;
}
.fa-solid.fa-house{
    font-size: 25px;
    
}
.fa-solid.fa-bars{

    font-size: 25px;
}
@media only screen and (max-width:700px)  {
    

    .nav-main-div{
        display: flex;
    }
}