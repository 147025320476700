@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Outfit:wght@100..900&display=swap');

.main-type-div{
    display: flex;
    justify-content: center;
    font-family: "Outfit", sans-serif;
    padding: 10px;
    flex-wrap: wrap;
   
}
.type-div{

    width: 23%;
    height: 300px;
    background-color: beige;
    margin: 10px;
    font-size: 35px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* color:#000080; */
    /* color:#ca855f; */
    color: #DCAE96;
    
}
.type-img{

    width: 100%;
    height: 100%;
    object-fit: cover;

}

.img-circle{

    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    margin: 10px;
}

@media only screen and (max-width:700px){


    .type-div{
        width: 90%;
    }

    .img-circle{

        width: 80%;

    }
}