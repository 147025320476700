.adddetails{

   border: 1px solid grey;
   width: 300px;
   height: 30px;
}
.addproductdiv{

   display: flex;
   flex-direction: column;
   margin-left: 10px;
}

.sub{

   width: 80px;
   background-color: green;
   margin-top: 10px;
}
.upload_image{

   display: flex;
   flex-direction: column;
}
.dialog_input{

   margin: 5px;
   border-radius: 3px;
   border:1px solid grey;
   height: 40px;
   padding-left: 4px;
   background-color: rgb(243 244 246);
   width: 295px;

}

.textarea{

   width: 300px;
   height: 80px;
}
.edit_dialog_box{

   width: 60%;
   height: 500px;
   position: fixed;
   background-color: white;
   border-radius: 8px;
   box-shadow: 1px 1px 7px;
   left:50%;
   top:50%;
   transform: translate(-50%,-50%);
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   padding: 10px;
   
   
}


.table_div{

   display: flex;
}
.margin{

   margin:10px;
   width:200px;
   height: 160px;
}
.gr{color: green;}

.submit_done{

   width: 150px;
   height: 40px;
   background-color: lawngreen;
   border-radius: 12px;
   border:1px solid white;
   transition: 0.5s ;
   position: relative;
}
.submit_done:hover{
   
   background-color: rgb(124, 236, 12);
  
}

.close_mark_edit{

   position: absolute;
   right: 0px;
   margin-right: 17px;
   margin-top: 3px;
   font-size: 17px;
  
}
.edit_main{

   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   margin-right: 20px;
   margin-left: 20px;
   margin-top: 20px;
   
}

.images_column{

   display: grid;
   justify-items: center;
   grid-template-columns: repeat(2,1fr);
   flex-wrap: wrap;
   height: 100%;
   width: 100%;
   margin-top: 10px;
   margin-right: 15px;
}
.allimages_div2{

   background-color: white;
   border-radius: 10px;
   box-shadow: 1px 1px 8px grey;
   padding: 10px;
}


.hover_image{

   width: 70%;
   height: 150px;
   border-radius: 10px;
   object-fit:cover;
   box-shadow: 1px 1px 8px rgb(133, 98, 172);
   border: 1px solid grey;

}
.wrong{

   position: absolute;
   right: 10px;
   margin-top: 5px;
}


@media only screen and (min-width:1000px){

   .edit_dialog_box{
      width: 50%;
   }
}

@media only screen and (max-width:1300px){
   .edit_dialog_box{
      
      width: 60%;
   }

}
@media only screen and (max-width:1150px){
   .edit_dialog_box{
      
      width: 80%;
   }

}
@media only screen and (max-width:850px){
   .edit_dialog_box{
      
      width: 90%;
   }

}
@media only screen and (max-width:750px){
   .edit_dialog_box{
      
      width: 60%;
   }

   

}

@media only screen and (max-width:570px){

   

   
   .edit_dialog_box{
      width: 90%;
      height: 95%;
      margin-left: 10px;
      margin-right: 10px;
   }
   .dialog_input{

      width: 97%;
   }
   
   }

   @media only screen and (max-height:700px){
      

      .hover_image{
         height: 120px;
      }
      
    }