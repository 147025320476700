.admin_div{

    display: flex;
    padding:10px;

}
.view{

    margin: 10px;
    border-radius: 5px;
    background-color:wheat;
    width: 150px;
    height: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}