.card-container{

    
    
    padding: 5px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    

    
}
@media only screen and (max-width:700px) {
    .card-container{

        /* margin-bottom: 55px; */
    }
    
}