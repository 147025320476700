.imgs{
    max-width:100px;
    max-height:200px;

}

.object-cover{

    width:100%;
    
    
    
}
/* .product-add-button{

    display: flex;
    justify-content: space-between;
} */

.cards:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
   }
.cards{

    width: 190px;
    height: 254px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;gap: .5em;
    display: grid;
    place-content: center;

     /* width:13%;
    height:300px;
    background: white;
    padding: .4em;
    border-radius: 6px;
    display: flex;
   flex-direction: column;
   align-items: center; */


    /*border:2px solid grey;
    margin:5px;
    padding: 10px;
     */
   /* margin:10px;
   padding: 10px;
   
   flex-direction: column;
   
   box-sizing: border-box;
   width: 14%;
   height: 300px;
   /* background: rgba(217, 217, 217, 0.58); */
   /* border: 1px solid white;
   box-shadow: 12px 17px 52px rgba(0, 0, 0, 0.22); */
   /* backdrop-filter: blur(6px); */
   /*border-radius: 17px;
   text-align: center;
   cursor: pointer;
   transition: all 0.5s;
   display: flex;
   align-items: center;
   justify-content: center;
   user-select: none;
   font-weight: bolder;
   color: black; */
   
    
    
   

}

.name-title{

    /* min-height: 60px;
    max-height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap; */

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em; /* 2 lines with 1.5em line height */
    line-height: 1.5em; /* Adjust based on your font size */
}

.product-name-weight{

    font-weight: 580;
}
.counter.counter-disable{
    display: none;
}

.product-add-button.product-add-button-disable{

    display: none;
}

.rate-div{
    display: flex;
    align-items: flex-end;
    margin-top: 20px;

    
}
.rate{

    margin-right: 5px;
    
}
.mrp{
    margin-left: 5px;
    margin-bottom: 3px;
    color: grey;
}

.span-mrp{

    text-decoration: line-through gray;
    
}
.view-product{
    width: 150px;
    height: 40px;
    background-color:#912BBC;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    margin-top: 30px;

}

/* .product-add-button{
    width: 90%;
    color:#1679AB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border :1px solid #1679AB;
    border-radius: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    margin-top: 15px;
} */
/* .counter{
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    color:#1679AB;
    background-color: red;
} */

.product-add-button{
    height: 45px;
}
.counter{
    height:45px;
}
.add{

font-size: 25px;
}
.show{
    font-size: 25px;
    width: 20%;
}

.addeds{

    visibility: hidden;
}
.addeds-enable{
   visibility: visible;
}
.mrp-red{

    color:grey;
}



@media only screen and (max-width:700px){
    
    .max-w-64{

        max-width: 45%;
    }
    .m-4{

        margin: 2px;
        
    }
    
.cards{
    width: 38%;
}
.view-product{

    width: 80%;
}


}

@media only screen and (max-width:550px){

   
    
    .h-52{
        max-height: 150px;
    }
}


@media only screen and (max-width:400px){

   
    
    .h-52{
        max-height: 130px;
    }
}