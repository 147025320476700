.allimages_div{


 position: absolute;
    

}

.wrong{
    
   
    
    }
    


/* .images_column{
display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;

} */
.allimages_div2{
    height: 100vh;
    /* width:100%; */
    
    position: fixed;
    background-color: white;
    width: 50%;
    height: 50%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    
}

@media only screen and (max-width:700px){
    .allimages_div2{

        width: 85%;
    }
}

