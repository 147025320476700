@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
.main-landing {
  width: 100%;
  height: 60vh;
  background-color: #ef9797;
  background-color: rgb(10, 10, 10);
  background-color: #201f1f;
  background-color:#DDD0C8;
  background-color: #E6F2E4;
  
  
  
  /* background-color: #1e90ff; */
  margin-top: 50px;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  




  background-image: url("../images/2.jpeg");
 
  background-position: 97% center;
  background-size: auto;
   
  background-repeat: no-repeat;
  background-blend-mode: multiply; 
  
}
.welcome {
  color: white;
  color: rgb(82, 82, 82);
  font-size: 25px;
}

.landing-page {
  color: #faeeee;
  color: #EAB8D1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;

  font-family: "Lilita One", sans-serif;
  
  font-weight: 900;
}
.abs{
    position: absolute;
    top:0px;
}

.key {
  font-size: 10vw;
  display: inline-block;
  letter-spacing: -1vw;
  transition: transform 0.2s;
}

@keyframes pressDown1 {
  30%,
  40%,
  100% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(10px);
  }
}

@keyframes pressDown2 {
  70%,
  80%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes pressDown3 {
  30%,
  40%,
  100% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(10px);
  }
}

@keyframes pressDown4 {
  40%,
  50%,
  100% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(10px);
  }
}

@keyframes pressDown5 {
  20%,
  30%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10px);
  }
}

@keyframes pressDown6 {
  60%,
  70%,
  100% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(10px);
  }
}

@keyframes pressDown7 {
  10%,
  20%,
  100% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(10px);
  }
}

@keyframes pressDown8 {
  35%,
  45%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
}

.key:nth-child(1) {
  animation: pressDown1 2s infinite;
}

.key:nth-child(2) {
  animation: pressDown2 3s infinite;
}

.key:nth-child(3) {
  animation: pressDown3 4s infinite;
}

.key:nth-child(4) {
  animation: pressDown4 2.5s infinite;
}

.key:nth-child(5) {
  animation: pressDown5 2.5s infinite;
}

.key:nth-child(6) {
  animation: pressDown6 3.5s infinite;
}

.key:nth-child(7) {
  animation: pressDown7 2.2s infinite;
}

.key:nth-child(8) {
  animation: pressDown8 3.2s infinite;
}



/*next text*/
.shop-now
{

    color:white;
    color: black;
    border:1px solid rgb(255, 255, 255);
    background-color: white;
    height: 40px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin :20px;
    transform: translate(-50%);
    margin-left: 50%;
    border-radius: 7px;
    
    
}  

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
.text-landing{

    /* position:relative;
    left: 0; */
    text-align: left;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: white;
    border-radius: 0 7px 7px 0;
    margin-top: 10px;
    background-color: #B2C8B2;
}
.text-landing-1{
  
    width: 50vw;
    /* border: solid yellow; */
    

}
 
.text-landing-2{
    
    width: 40vw;
    /* border: solid blue; */
    /* background-color: #ffef84; */
    

}
.text-landing-3{
    
    width: 60vw;
    /* border: solid red; */
    /* background-color: #70b8ff; */
    

}

  @media only screen and (max-width: 700px) {
    .key {
      font-size: 16vw;
    }
    .landing-page {
      flex-direction: column;

    
    }
    .text-landing-1{
        width: 60vw;

    }
    .text-landing-2{
        width: 50vw;
    }
    .text-landing-3{
        width: 70vw;
    }
    .main-landing{

        background-position: right 75% ;
        background-size: 200px 200px;
    }
    
  }